import axios from 'axios'
import requestPrint from '@/models/requestPrintModel';
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_PRINT_SERVER,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default {
  sendPrintQueue(requestPrint: requestPrint): Promise<any> {
    return new Promise((resolve) => {
      const response = apiClient.post('/queueLoket.php',requestPrint)
      resolve(response)
    }).catch((e) => {
      throw new Error('Gagal cetak tiket antrian')
    });
  },
  sendPrintQueuePoly(requestPrint: requestPrint): Promise<any> {
    return new Promise((resolve) => {
      const response = apiClient.post('/queuePoly.php',requestPrint)
      resolve(response)
    }).catch((e) => {
      throw new Error('error')
    });
  },
}
