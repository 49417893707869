import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import departmentModel from '@/models/admin/departmentModel'

const axiosOption = apiService

export default {
  getAll (): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/departments',
    }
    return axiosOption(apiService)
  },

  create (item: departmentModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/department',
      data: item
    }
    return axiosOption(apiService)
  },
  update (item: departmentModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'put',
      url: '/department/'+item.id,
      data: item
    }
    return axiosOption(apiService)
  },
  delete (item: number): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'delete',
      url: '/department/'+item,
    }
    return axiosOption(apiService)
  },
  
}