import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/types'

import { topToolbar } from './modules/topToolbar'
import { WsMessageHandler } from './modules/websocketHandler'
import userStore from './modules/userModule'
import { ApplicationModule } from './modules/applicationModules'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    topToolbar,
    WsMessageHandler,
    user: userStore,
    ApplicationModule
  },
};

export default new Vuex.Store<RootState>(store)
