import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'

const axiosOption = apiService

export default {
  getSetting (): AxiosPromise{
    const options: AxiosRequestConfig = {
      method: 'get',
      url: '/settings',
    }
    return axiosOption(options)
  },
}