const required = (value: string) => (value !== undefined && value !== null && value !== '' && value !== 'null') || 'This field is required'
const maxLength = (value: string, maxLength: number) => (value !== undefined && value !== null && value !== '') ? (value && value.length <= maxLength) || `Must be less than ${maxLength} characters` : true
const minLength = (value: string, minLength: number) => (value !== undefined && value !== null && value !== '') ? (value && value.length >= minLength) || `Must not be less than ${minLength} characters` : true
const email = (value: string) => (value !== undefined && value !== null && value !== '') ? /.+@.+\..+/.test(value) || 'Must be a valid email address' : true

export default {
  required,
  maxLength,
  minLength,
  email
}