import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { WsMessage, RootState } from '@/types';
import CallMessageWsModel from '@/models/CallMessageWsModel';

const parseJson = (val: string) => {
  const now = new Date()
  const oldJson = JSON.parse(val)
  const newJson = {...oldJson, timestamp: now}
  return JSON.stringify(newJson)
}

const state: WsMessage = {
  connected: false,
  message: ''
};

let wsClient = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL)

export const getters: GetterTree<WsMessage, RootState> = {
  message: (data: WsMessage) => {
    return data.message;
  },

  isConnected: (data: WsMessage) => {
    return data.connected
  }
};

const mutations: MutationTree<WsMessage> = {
  setMessage (state: WsMessage, message: string) {
    state.message = message;
  },

  setConnectionState (state: WsMessage, connectionState: boolean) {
    state.connected = connectionState;
  }
};

export const actions: ActionTree<WsMessage, RootState> = {
  changeMessage ({ commit }, message: string) {
    const newMsg = parseJson(message)
    commit('setMessage', newMsg);
  },

  changeConnectionState({ commit }, state: boolean) {
    commit('setConnectionState', state)
  },

  sendCallMessage ({}, message: string) {
    if (wsClient) wsClient.send(message)
  },

  connect({ commit }) {
    return new Promise((resolve) => {
      wsClient = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL)
      wsClient.onopen = () => {
        commit('setConnectionState', true)
        console.log('Websocket Connected .')
        resolve(true)
      };
    
      wsClient.onmessage = function(data) {
        const message = data.data
        commit('setMessage', message)
      }
    
      wsClient.onclose = () => {
        commit('setConnectionState', false)
        console.log('Websocket Disconnected !')
        resolve(false)
      }
    })
  }
};

export const WsMessageHandler: Module<WsMessage, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};