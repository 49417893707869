import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import UserModel from '@/models/auth/userModel'
const axiosOption = apiService

export default {
  login (credentials: any){
    const data = new FormData()
    data.append('email', credentials.username)
    data.append('password', credentials.password)
    data.append('client_secret', process.env.VUE_APP_SIMANTRI_SECRET)
    data.append('grant_type', 'password')
    data.append('client_id', '1')
    data.append('scope', '*')
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/login',
      data: data
    }
    return axiosOption(apiService)
  },
  cekValidToken (token: string): AxiosPromise<UserModel> {
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/me',
      headers: {
        Authorization: token,
      }
    }
    return axiosOption(apiService)
  },
  logout (): AxiosPromise<UserModel> {
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/logout',
    }
    return axiosOption(apiService)
  }
}