import Vue from 'vue'
import Vuex from 'vuex'
import UserModel from '@/models/auth/userModel'
import axios from 'axios'
Vue.use(Vuex)

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SIMANTRI,
  timeout: 5000,
})

const state: { user: UserModel | null, token:string, isAuth: boolean } = {
  user: null,
  token: '',
  isAuth: false
}

const mutations = {
  SET_USER(state: { user: UserModel | null }, user: UserModel | null) {
    state.user = user
  },
  SET_TOKEN(state, token: string) {
    state.token = token
    if (token) {
      localStorage.setItem('token',token)
    } else {
      localStorage.removeItem('token')
    }
  },
  SET_IS_AUTH(state, isAuth: boolean | false) {
    state.isAuth = isAuth
  }
}

const actions = {
  cekValidToken({ commit }, access_token: string) {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/me', { headers: { Authorization: 'Bearer ' + access_token, 'Content-Type': 'application/json' } }).then((response) => {
        commit('SET_IS_AUTH', true)
        commit('SET_USER', response.data.responseData)
        commit('SET_TOKEN', access_token)
        resolve(response)
      }).catch((err) => {
        commit('SET_IS_AUTH', false)
        commit('SET_TOKEN', '')
        commit('SET_USER',{})
        reject(err)
      })
    })
  },

  setToken ({ commit }, access_token: string) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', access_token)
      resolve(true)
    })
  },

  logOut ({commit}, access_token: string) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/logout', null, {
        headers: {
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        commit('SET_IS_AUTH', false)
        commit('SET_TOKEN', '')
        commit('SET_USER', {})
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
    })    
  }
}


const getters = {
  user: (state: { user: UserModel | null }) => state.user,
  token: (state:{ token: string | null }) => state.token,
  isAuth: (state:{ isAuth: boolean | false }) => state.isAuth,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
