import axios, { AxiosResponse, AxiosError } from 'axios'

const apiBaseUrl = process.env.VUE_APP_SIMANTRI
const access_token = localStorage.getItem('token')
interface MyResponseData {
  message: string;
}

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${access_token}`
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.responseCode !== 200) {
      throw new Error(response.data.responseDesc)
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const responseData = error.response.data as MyResponseData;
      const errorMessage = responseData.message || 'Unknown error message';
      return Promise.reject(errorMessage);
    } else if (error.request) {
      return Promise.reject('Network error');
    } else {
      return Promise.reject(error);
    }
  }
)


export default axiosInstance;
